<!--
NOTES:
- Align prod rows quantities and totals
- prices align right
- only show headers for first row
- only show inline editing if logged in

-->
<template>
  <div class="bg-main-backgroud w-full flex flex-col overflow-y-auto">
    <div class="flex flex-grow w-full justify-center max-w-full bg-main-backgroud">
      <div class="flex flex-grow bg-gray-100">
        <Nuxt />
      </div>
    </div>
    <LoginPanel
      v-show="false"
      ref="login"
    />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      login: false,
      showSignin: false,
      inIframe: false
    }
  },
  head() {
    return {
      bodyAttrs: {
        class: this.$route.name
      }
    }
  },
  computed: {
    businessCode() {
      return this.$store.getters.getBusinessCode
    },
    invoiceCode() {
      return this.$store.getters.getInvoiceCode
    },
    username() {
      return this.$store.getters.getUsername
    },
    loggedInUser() {
      return this.$store.getters.loggedInUser
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    invoice() {
      return this.$store.state.invoice
    },
    status() {
      return getInvoiceStatus(this.invoice)
    }
  },
  async mounted() {
    if (window.self !== window.top) {
      this.inIframe = true
    }

    if (this.$store.loggedInUser) {
      return
    }

    const username = `anonymous@${this.$route.params.businessCode}`
    this.$store.commit('SET_USERNAME', username)
    await this.$refs.login.signIn(username)
    this.$store.commit('SET_BUSINESS_CODE', this.$route.params.businessCode)
  }
}

</script>
